import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { VacancyRelationState } from "../../types/recruiter/relations";
import { useState } from "react";

type Props = {
    state: VacancyRelationState;
    states: VacancyRelationState[];
    onChange?: (status: VacancyRelationState) => void;
}

export function RelationStatusButton(props: Props) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    return <Box sx={{ background: props.state.color, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: props.onChange && 'pointer' }}>
        <Box onClick={handleClick} sx={{ px: 1 }}>
            <Typography variant="overline">
                {
                    props.state.name
                }
            </Typography>
        </Box>

        {props.onChange &&

            <Menu
                keepMounted={true}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiList-root': {
                        py: 0
                    }
                }}
            >
                {
                    [...props.states].sort((a, b) => a.index - b.index)
                        .map((status) => <MenuItem key={status._id} sx={{ background: status.color }}
                            onClick={() => {
                                if (!props.onChange) return;

                                props.onChange(status);
                                handleClose()
                            }}>
                            <Box sx={{ p: 1 }}>
                                <Typography variant="overline" sx={{ lineHeight: 1 }}>
                                    {status.name}
                                </Typography>
                            </Box>
                        </MenuItem>)
                }
            </Menu>
        }
    </Box>
}