import { useCallback, useRef } from "react";
import { MatchScore, MatchScoreStatus } from "../../types/recruiter";
import { delay } from "../../utils/delay";
import api from "../../api/recruiter/relations";

/**
 * Polling version: will refetch scores until at least one score changes or we exceed maxAttempts.
 * @param scores     Current list of scores
 * @param onChange   Callback to update the scores
 * @param maxAttempts Optional maximum number of attempts to avoid infinite polling
 * @returns          A function you can invoke to start checking for missing scores.
 */
export const useMissingScoresHook = (
    scores: MatchScore[],
    onChange: (scores: MatchScore[]) => void,
    maxAttempts = 15
) => {
    const attemptsRef = useRef(0);

    const checkScores = useCallback(async function run() {
        await delay(3000);

        const pendingScores = scores.filter((x) => x.status === MatchScoreStatus.Pending);
        if (pendingScores.length === 0) return; // Nothing pending, no need to poll further.

        try {
            const newScores = await api.getScores(pendingScores);

            // Check if any statuses differ
            const someScoreChanged = newScores.some(
                (newScore) => {
                    const oldScore = scores.find((y) => y._id === newScore._id);
                    return oldScore && oldScore.status !== newScore.status;
                }
            );

            if (!someScoreChanged) {
                // FIX: we want to run the hook (i.e. poll) again
                // but let's protect ourselves with a maxAttempts guard
                attemptsRef.current += 1;

                if (attemptsRef.current < maxAttempts) {
                    console.log("Scores haven’t changed. Retrying...");
                    setTimeout(run, 3000);  // re-call the same function
                } else {
                    console.warn("Max attempts reached. Stopping polling.");
                }
                return;
            }

            // At least one score changed. Update them all locally.
            const updatedScores = scores.map((score) => {
                const newScore = newScores.find((x) => x._id === score._id);
                return newScore || score;
            });

            onChange(updatedScores);
            // Reset attempts since we got a change
            attemptsRef.current = 0;

        } catch (error) {
            console.error("Error fetching professional scores:", error);
        }
    }, [scores, onChange, maxAttempts]);

    return checkScores;
};